// External dependencies
import { Container, Row, Col, Text, Div } from 'atomize';
import { StaticImage } from 'gatsby-plugin-image';
import { FormattedMessage } from 'gatsby-plugin-react-intl';
import React from 'react';

// Internal dependencies
import BackNav from '../nav/BackNav';

const CustomsRepresentativeGuide = () => {
  return (
    <Container>
      <Row m={{ y: '1rem' }}>
        <BackNav
          label={<FormattedMessage id={`howToUseGoods`} />}
          path={'/login'}
        />
      </Row>

      <Row w="100%" p={{ l: '1.5rem' }}>
        <Col size={{ xs: '12' }}>
          <Div>
            <Text textSize="display2">Первый шаг:</Text>
            <ol style={{ padding: 0 }}>
              <li>
                <Text textWeight="500">
                  Первым делом нужно будет перейти по этой ссылке и войти в
                  систему
                </Text>

                <ul type="square" style={{ padding: 0 }}>
                  <li>
                    Если вы в первые заходите в систему, то после того как админ
                    вас добавил в систему вам придет смс с паролем.
                  </li>
                  <li>
                    Нужно ввести номер и пароль. Дальше нужно будет вам самим
                    создать новый пароль для дальнейшего использования.
                  </li>
                </ul>
              </li>
            </ol>
            <StaticImage
              src="../../assets/images/step-1.png"
              alt="Login page"
              placeholder="blurred"
              layout="fullWidth"
              style={{
                margin: '0 auto',
                maxWidth: 600,
              }}
            />
          </Div>
          <Div>
            <Text textSize="display2">Второй шаг:</Text>
            <ol style={{ padding: 0 }}>
              <li>
                <Text textWeight="500">
                  После того как вы вошли в систему вы увидите такую страницу
                </Text>

                <StaticImage
                  src="../../assets/images/step-2-1.png"
                  alt="Goods specifications page"
                  placeholder="blurred"
                  layout="fullWidth"
                />
              </li>
              <li>
                <Text textWeight="500">
                  Дальше чтобы сгруппировать спецификацию товаров сделайте
                  следующие действия:
                </Text>

                <ul type="square" style={{ padding: 0 }}>
                  <li>
                    Нажмите на кнопку{' '}
                    <strong>"Добавить спецификацию товаров"</strong>
                  </li>
                  <li>
                    Выйдет модальное окно
                    <StaticImage
                      src="../../assets/images/step-2-2.png"
                      alt="Goods add modal"
                      placeholder="blurred"
                      layout="fullWidth"
                      style={{
                        margin: '0 auto',
                        maxWidth: 500,
                      }}
                    />
                  </li>

                  <li>
                    Нужно будет заполнить все три поля
                    <ul type="circle" style={{ padding: 0 }}>
                      <li>Грузополучатель</li>
                      <li>Валюту</li>
                      <li>
                        Загрузить сам файл. Перед тем как загруить фалй,
                        убедитесь в правильности имен столбцов. Чтобы правильно
                        сгруппировать файлы, Вам нужно будет изменить имена
                        некоторых столбцов.
                      </li>
                      <li>
                        Наша система группирует файл по столбцу{' '}
                        <code>
                          <strong>hs-code</strong>
                        </code>
                        , поэтому если вы хотите чтобы группировка шла по
                        какому-то столбцу, удедитесь что вы переименовали имя
                        этого столбца на{' '}
                        <code>
                          <strong>hs-code</strong>
                        </code>
                      </li>
                      <li>
                        Второй уровень группировки идет по столбцу{' '}
                        <code>
                          <strong>country</strong>
                        </code>{' '}
                        , во многих файлах идет двух уровневая группировка, если
                        вы хотите чтобы группировка шла и по стране или по коду
                        страны, то переименуйте имя данного столбца на{' '}
                        <code>
                          <strong>country</strong>
                        </code>
                      </li>
                      <li>
                        Если вы хотите дублировать некоторые столбцы к примеру
                        сумму или название, то нужно будет дать такому столбцу
                        имя{' '}
                        <code>
                          <strong>total-price</strong>
                        </code>{' '}
                        или{' '}
                        <code>
                          <strong>name</strong>
                        </code>
                      </li>
                      <li>
                        Дальше нажимаете кнопку <strong>"Добавить"</strong>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ol>
          </Div>
          <Div>
            <Text textSize="display2">Третий шаг:</Text>
            <ol style={{ padding: 0 }}>
              <li>
                <Text textWeight="500">
                  Дальше после добавления спецификации нужно будет обновить
                  страницу, и в списке появится файл, который Вы добавили.
                </Text>
              </li>
              <li>
                <Text textWeight="500">
                  Теперь можете <strong>"скачать"</strong> сгруппированный файл
                </Text>
                <StaticImage
                  src="../../assets/images/step-3.png"
                  alt="Goods specifications page"
                  placeholder="blurred"
                  layout="fullWidth"
                />
              </li>
            </ol>
          </Div>
        </Col>
      </Row>
    </Container>
  );
};

export default CustomsRepresentativeGuide;
