/* External dependencies */
import { graphql, useStaticQuery } from 'gatsby';
import { useIntl } from 'gatsby-plugin-react-intl';
import React from 'react';
import { Helmet } from 'react-helmet';

/* Local dependencies */
import CustomsRepresentativeGuide from '../../components/guides/CustomsRepresentativeGuide';
import Layout from '../../components/layout';
import formatMessage from '../../intl/formatMessage';

export default function GoodsSpecifications() {
  const intl = useIntl();
  const locale = intl.locale;
  const title = formatMessage('goodsSpecifications');
  const {
    site: {
      siteMetadata: { titleTemplate },
    },
  } = useStaticQuery(query);

  return (
    <Layout>
      <Helmet>
        <title>{titleTemplate.replace('%s', title)}</title>
      </Helmet>
      <CustomsRepresentativeGuide />
    </Layout>
  );
}

const query = graphql`
  query {
    site {
      siteMetadata {
        titleTemplate
      }
    }
  }
`;
