/* External dependencies */
import { Col, Div, Icon, Row, Text } from 'atomize';
import { navigate } from 'gatsby-plugin-react-intl';
import React from 'react';

interface BackNavProps {
  label: React.ReactChild;
  onClick?: () => void;
  path?: string;
}

export default function BackNav({ label, onClick, path }: BackNavProps) {
  const linkStyles = {
    display: 'flex',
    verticalAlign: 'center',
    cursor: 'pointer',
  };

  if (path && !onClick) {
    onClick = () => navigate(path);
  }

  return (
    <Row m={{ b: '40px' }}>
      <Col>
        <Div style={linkStyles} onClick={onClick}>
          <Icon
            p="0"
            name="LongLeft"
            size={{ xs: '2rem', sm: '2.5rem' }}
            m={{ l: '-10px', r: { xs: '0.5rem', sm: '1rem' } }}
          />
          <Text
            tag="h3"
            textSize={{ xs: 'mobileTitle', sm: 'display2' }}
            textWeight="700"
            textColor="table_cell_text_color"
            style={{ overflowWrap: 'anywhere' }}
          >
            {label}
          </Text>
        </Div>
      </Col>
    </Row>
  );
}
